<template>
  <div>
    <Header :headerIndex="3"></Header>
    <div class="container">
      <img class="g_top_banner" src="../assets/static/icon/icon_top_banner3.png" alt="" />
      <div class="product_center_wrap">
        <div class="product_center_inner max_1440">
          <div class="g-select-wrap">
            <el-select class="g-select mr30" v-model="seriesValue" placeholder="Select Product Series"  @click.native="getserise">
              <el-option @click.native="seriesclear" value="">Select Product Series</el-option>
              <el-option v-for="(item,index) in seriesOptions" :key="index" :label="item.name_en" :value="index" @click.native="getseriseid(item.id)"> </el-option>
            </el-select>
            <el-select class="g-select" v-model="colorValue" placeholder="Choose Color Model" @click.native="getcolor" > 
              <el-option @click.native="colorclear" value="">Choose Color Model</el-option>
              <el-option v-for="(item,index) in colorOptions" :key="index" :label="item.name_en" :value="item.name" @click.native="getcolorid(item.id)"> </el-option>
            </el-select>
          </div>

          <div class="g_product_list flex-box flex-wrap flex-col-start">
            <router-link :to="{path:'/ColorCardDetail',query:{colorid:item.id}}" class="product_item" v-for="(item, index) in colorlist" :key="index">
              <img class="img" :src="item.image_url" alt="" />
              <div class="name m-ellipsis-l2">{{item.name_en}}</div>
            </router-link>
          </div>
          <div :style="{display:display}">
            <Pagination :sumCount="total" :now="page" :perCount="per_page" @pageTurn="pageTurn"></Pagination>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  components: {},
  data() {
    return {

      seriesOptions: [
      ],
      seriesValue: "",

      colorOptions: [
      ],
      colorValue: "",
      seriesid:0,
      colorid:0,
      total: 1,
      colorlist:[],
      per_page: 8,
      display:''
    };
  },
  methods: {
    pageTurn(page) {
      console.log(page)
      this.page = page+1;
      var json = {
        page:this.page,
        limit:this.per_page,
        typepid:this.seriesid,
        typeid:this.colorid
      }
      axios.post('https://n.xilukeji.com/rwf/index.php/Shop/Index/colorlist_2023',json).then(res=>{
          // console.log(res)
          if(res.data.count == 0){
            this.total =1 
            this.colorlist = []
          }else{
            this.colorlist = res.data.info
            this.total = parseInt(res.data.count)
          }
          if(this.total<=this.per_page){
            this.display = 'none'
          }else{
            this.display = 'block'
          }
      })
    },
     //获取series列表
     getserise(){
      axios.get('https://n.xilukeji.com/rwf/index.php/Shop/Index/typelist_2023').then((res)=>{
          // console.log(res)
          if(res.status){
            this.seriesOptions=res.data.data.type
          }         
      })
    },
    //获取颜色列表
    getcolor(){ 
      if(this.seriesValue>=0&&this.seriesValue !== ''){
        // console.log(this.seriesValue)
        this.colorOptions = this.seriesOptions[this.seriesValue].child
      }else{
        this.colorOptions = []
        this.colorValue='Choose Color Model'
      }
    },
    getseriseid(id){
        // console.log('series',id)
        this.seriesid = id
        this.page=1
        console.log('this.page',this.page);
        this.colorValue =''
        this.colorid = 0  
        this.getcolorlist()
    },
    seriesclear(){
      this.seriesid = 0   //typepid = 0
      this.colorid = 0    //typeid = 0
      this.seriesValue = ''
      this.getcolor()
      this.getcolorlist()
    },  
    getcolorid(id){
      // console.log('colorid',id)
      this.colorid = id
      this.page =1
      this.getcolorlist()
    },
    colorclear(){
      this.colorid = 0,
      this.colorValue = ''
      this.getcolorlist()
    },
    //获取色卡列表
    getcolorlist(){
      this.pageTurn(0)
    }

  },
  created(){
    this.getcolorlist()
  }
};
</script>
